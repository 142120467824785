// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blogs-jsx": () => import("./../../../src/templates/blogs.jsx" /* webpackChunkName: "component---src-templates-blogs-jsx" */),
  "component---src-templates-career-jsx": () => import("./../../../src/templates/career.jsx" /* webpackChunkName: "component---src-templates-career-jsx" */),
  "component---src-templates-careers-jsx": () => import("./../../../src/templates/careers.jsx" /* webpackChunkName: "component---src-templates-careers-jsx" */),
  "component---src-templates-company-jsx": () => import("./../../../src/templates/company.jsx" /* webpackChunkName: "component---src-templates-company-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-horizontals-jsx": () => import("./../../../src/templates/horizontals.jsx" /* webpackChunkName: "component---src-templates-horizontals-jsx" */),
  "component---src-templates-privacy-cookies-policy-jsx": () => import("./../../../src/templates/privacy-cookies-policy.jsx" /* webpackChunkName: "component---src-templates-privacy-cookies-policy-jsx" */),
  "component---src-templates-reference-jsx": () => import("./../../../src/templates/reference.jsx" /* webpackChunkName: "component---src-templates-reference-jsx" */),
  "component---src-templates-references-jsx": () => import("./../../../src/templates/references.jsx" /* webpackChunkName: "component---src-templates-references-jsx" */),
  "component---src-templates-solutions-jsx": () => import("./../../../src/templates/solutions.jsx" /* webpackChunkName: "component---src-templates-solutions-jsx" */),
  "component---src-templates-team-jsx": () => import("./../../../src/templates/team.jsx" /* webpackChunkName: "component---src-templates-team-jsx" */),
  "component---src-templates-verticals-jsx": () => import("./../../../src/templates/verticals.jsx" /* webpackChunkName: "component---src-templates-verticals-jsx" */)
}

