import React from 'react';

import PageProvider from './src/providers/page';
import ScreenSize from './src/providers/screen-size';

export const wrapRootElement = ({ element }) => {
	return (
		<PageProvider>
			<ScreenSize>{element}</ScreenSize>
		</PageProvider>
	);
};

export const wrapPageElement = ({ element, props }) => {
	return (
		<PageProvider pageContext={props.pageContext}>
			<ScreenSize>{element}</ScreenSize>
		</PageProvider>
	);
};
